<template>
    <v-app>
        <v-main>
            <ResumeComponent/>
        </v-main>
    </v-app>
</template>

<script>
import ResumeComponent from '../components/ResumeComponent'

export default {
    components: {
        ResumeComponent
    }
}
</script>

<style scoped>
/* Add any necessary scoped CSS styles */
</style>
